export const TRACKING_ELEMENTS = [
  "ref",
  "utm_campaign",
  "utm_medium",
  "utm_source",
  "utm_term",
  "utm_content",
  "code",
  "gclid",
  "fbclid",
  "f_page",
];

export const MIXPANEL_SUPERPROPERTIES = ["ref", "code", "f_page"];
