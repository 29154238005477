import {
  ALTERNATIVES_PATH,
  APP_URL,
  BLOG_PATH,
  CANNED_RESPONSE_TEMPLATES_PATH,
  CHAT_TEMPLATES_PATH,
  COMMUNITY_PATH,
  EMAIL_TEMPLATES_PATH,
  GLOSSARY_PATH,
  MONOLANGUAGE_PAGES,
  PICTURES_ENDPOINT,
  SUCCESS_STORIES_PATH,
  SURVEY_TEMPLATES_PATH,
  USE_TEMPLATE_URL,
  WEBINARS_PATH,
} from "../constants/paths";
import { slugify } from "./string";

// TODO: Check how to use baseUrl in gatsby-node.js

const getAlternativePath = (slug: string): string => {
  return `${ALTERNATIVES_PATH}/${slug}/`;
};

const getWebinarsPath = (slug: string): string => {
  return `${WEBINARS_PATH}/${slug}/`;
};

const getSuccessStoriesPath = (slug: string): string => {
  return `${SUCCESS_STORIES_PATH}/${slug}/`;
};

const getBlogPathBySlug = (slug: string): string => {
  return `${BLOG_PATH}/${slug}/`;
};

const getGlossaryPathBySlug = (slug: string): string => {
  return `${GLOSSARY_PATH}/${slug}/`;
};

const getBlogPathByUri = (uri: string): string => {
  return `${BLOG_PATH}${uri}/`;
};

const getBlogCategoryPath = (slug: string): string => {
  return `${BLOG_PATH}/category/${slug}/`;
};

const getBlogTagPath = (slug: string): string => {
  return `${BLOG_PATH}/tag/${slug}/`;
};

const getAuthorWebsitePath = (uri: string): string => {
  const redirectUrlObj = new URL(uri);
  redirectUrlObj.searchParams.append("utm_source", "Customerly.io");
  redirectUrlObj.searchParams.append("utm_medium", "Community Template Page");
  redirectUrlObj.searchParams.append(
    "utm_campaign",
    "Customerly Community Page"
  );
  return redirectUrlObj.toString();
};

const getAccountPicturePath = (accountId: number): string => {
  return `${PICTURES_ENDPOINT}/accounts/${accountId}/512/light`;
};

const getBlogWithPagePath = (uri: string, page: number): string => {
  return `${getBlogPathByUri(uri)}${page}/`;
};

const getTemplateSignupUrl = (templateType: string, templateId: number) => {
  return `${USE_TEMPLATE_URL}/${templateType}/${templateId}`;
};

const getEmailTemplateSignupUrl = (templateId: number) => {
  return `${APP_URL}/email-template-builder/${templateId}`;
};

const getEmailTemplatePagePath = (
  templateId: number,
  name: string,
  creatorName: string
): string => {
  return `${EMAIL_TEMPLATES_PATH}/${slugify(name)}-${templateId}-by-${slugify(
    creatorName
  )}/`;
};

const getChatTemplatePagePath = (
  templateId: number,
  name: string,
  creatorName: string
): string => {
  return `${CHAT_TEMPLATES_PATH}/${slugify(name)}-${templateId}-by-${slugify(
    creatorName
  )}/`;
};

const getCannedResponseTemplatePagePath = (
  templateId: number,
  name: string,
  creatorName: string
): string => {
  return `${CANNED_RESPONSE_TEMPLATES_PATH}/${slugify(
    name
  )}-${templateId}-by-${slugify(creatorName)}/`;
};

const getSurveyTemplatePagePath = (
  templateId: number,
  name: string,
  creatorName: string
): string => {
  return `${SURVEY_TEMPLATES_PATH}/${slugify(name)}-${templateId}-by-${slugify(
    creatorName
  )}/`;
};

const getCommunityAuthorPagePath = (username: string): string => {
  return `${COMMUNITY_PATH}/${slugify(username)}/`;
};

const getLocalizedPath = (
  locale: string,
  path: string,
  defaultLanguage: string
) => {
  const localePrefix = locale !== defaultLanguage ? "/" + locale : "";
  return `${localePrefix}${path}`;
};

const isMonolanguagePage = (path: string) => {
  let isMonolanguage = false;

  MONOLANGUAGE_PAGES.forEach((page) => {
    if (path.includes(page)) {
      isMonolanguage = true;
    }
  });

  return isMonolanguage;
};

export {
  getAlternativePath,
  getGlossaryPathBySlug,
  getWebinarsPath,
  getLocalizedPath,
  getSuccessStoriesPath,
  getEmailTemplatePagePath,
  getChatTemplatePagePath,
  getCannedResponseTemplatePagePath,
  getSurveyTemplatePagePath,
  getCommunityAuthorPagePath,
  getBlogPathBySlug,
  getBlogPathByUri,
  getBlogCategoryPath,
  getBlogTagPath,
  getAuthorWebsitePath,
  getBlogWithPagePath,
  isMonolanguagePage,
  getAccountPicturePath,
  getTemplateSignupUrl,
  getEmailTemplateSignupUrl,
};
